import { useRef } from "react"
import { useIsVisible } from "../../lib/useIsVisible"

// @ts-expect-error
import annotationsImage from "./images/noOverlay/annotations.jpg?w=600&format=webp"
// @ts-expect-error
import colorsImage from "./images/noOverlay/colors.jpg?w=600&format=webp"
// @ts-expect-error
import hierarchyImage from "./images/noOverlay/hierarchy.jpg?w=600&format=webp"

// @ts-expect-error
import annotationsImageOverlay from "./images/overlay/annotations.png?w=600&format=webp"
// @ts-expect-error
import colorsImageOverlay from "./images/overlay/colors.png?w=600&format=webp"
// @ts-expect-error
import hierarchyImageOverlay from "./images/overlay/hierarchy.png?w=600&format=webp"

// @ts-expect-error
import flowImageOverlay from "./images/overlay/flow.png?w=600&format=webp"

// @ts-expect-error
import flowImage from "./images/noOverlay/flow.jpg?w=600&format=webp"

export function Features() {
  return (
    <section className="grid gap-12 bg-background">
      <Feature
        title="Upload your style guide."
        description="Turn your expert knowledge into a force multiplier for everyone in your team."
        leftImage={annotationsImage}
        rightImage={annotationsImageOverlay}
        imageAlt="unique style guide"
      />
      <Feature
        title="Spot issues instantly."
        description="Upload your design and skip waiting for reviews. Catch issues before they reach the feedback stage."
        leftImage={hierarchyImage}
        rightImage={hierarchyImageOverlay}
        imageAlt="highlight issues before they happen"
      />

      <Feature
        title="Get suggestions based on your style."
        description="Streamline your process with annotations that respect your style and appear right next to areas of interest in your image."
        leftImage={colorsImage}
        rightImage={colorsImageOverlay}
        imageAlt="suggestions"
      />

      <Feature
        title="Save time with seamless integrations."
        description="Easily check your work, no matter where your team collaborates."
        leftImage={flowImage}
        rightImage={flowImageOverlay}
        imageAlt="integrations"
      />
    </section>
  )
}

type FeatureProps = {
  title: string
  description: string
  leftImage?: string
  rightImage?: string
  imageAlt?: string
}

function Feature({
  title,
  description,
  leftImage,
  rightImage,
  imageAlt,
}: FeatureProps) {
  let annotationImageRef = useRef<HTMLImageElement>(null)
  let isVisible = useIsVisible(annotationImageRef)

  return (
    <div className="flex flex-col md:flex-row gap-2 lg:gap-4 md:justify-between lg:justify-start">
      <div className="space-y-4 max-w-lg w-full">
        <h2 className="text-2xl">{title}</h2>
        <p className="text-muted-foreground text-balance">{description}</p>
      </div>
      {leftImage && (
        <div className="relative w-full md:w-96 h-48">
          <img
            src={leftImage}
            alt={imageAlt}
            className="absolute rounded-lg w-full shadow-lg max-h-48 object-cover"
          />
          <img
            src={rightImage}
            alt={imageAlt}
            ref={annotationImageRef}
            className={`
						absolute
						rounded-lg
						shadow-lg
						w-full
						md:w-96
						max-h-48
						object-cover
						transition-opacity
						ease-in
						duration-1000
						delay-1000
						hover:opacity-0
						${isVisible ? "opacity-100" : "opacity-0"}
					`}
          />
        </div>
      )}
    </div>
  )
}
